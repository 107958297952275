import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../images/istock/i1.jpg';
import ChangeTitle from '../ChangeTitle';
import { FaEye } from 'react-icons/fa';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;
  const navigate = useNavigate();
  ChangeTitle("Blog | Forcode");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Articles'));
        const postsList = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            time: data.CreatedAt ? data.CreatedAt.toDate() : new Date(0),
          };
        });
        setPosts([...postsList].sort((a, b) => b.time - a.time));
      } catch (error) {
        console.error('Veriler çekilirken hata oluştu:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handlePostClick = (id) => {
    navigate(`/${id}`);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    const results = posts.filter(post =>
      post.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPosts(results);
  }, [searchTerm, posts]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredPosts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(startPage + 4, pageNumbers.length);

  return (
    <div>
      <div
        className='w-100 position-relative mb-5'
        style={{
          maxHeight: '300px',
          overflow: 'hidden',
          marginTop: '80px'
        }}
      >
        <img
          className="d-block w-100"
          src={bg}
          alt="Sap Logo"
          style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
            zIndex: 1,
          }}
        >
          <div className='d-flex flex-column justify-content-center align-items-center h-100 p-3'>
            <div className='col-12 col-md-8'>
              <h1 className='text-white text-center' style={{ fontSize: 'calc(1.2rem + 1vw)' }}>
                BLOG
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {loading ? (
          <div className="text-center">Yükleniyor...</div>
        ) : (
          <div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Blog yazılarını ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  padding: '10px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  fontSize: '1rem',
                  marginBottom: '20px'
                }}
              />
            </div>
            <div className="row">
              {currentPosts.map(post => {
                const createdAtDate = new Date(post.CreatedAt.seconds * 1000);
                const formattedDate = createdAtDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric' });
                const formattedTime = createdAtDate.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });

                return (
                  <div key={post.id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <div
                      className="card"
                      onClick={() => handlePostClick(post.id)}
                      style={{
                        cursor: 'pointer',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      {post.Image ? (
                        <img
                          src={post.Image}
                          className="card-img-top"
                          alt={post.Title}
                          style={{
                            height: '200px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#f0f0f0',
                            color: '#888',
                            fontSize: '1rem',
                            textAlign: 'center',
                            border: '1px solid #ddd',
                          }}
                        >
                          <span>Kapak Fotoğrafı Yok</span>
                        </div>
                      )}
                      <div
                        className="card-body p-3"
                        style={{
                          height: '100px'
                        }}
                      >
                        <h5
                          className="card-title"
                          style={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#5c3f97'
                          }}
                        >
                          {post.Title}

                        </h5>
                        <p className="card-text my-0"
                          style={{
                            fontSize: '0.75rem',
                            color: 'GrayText',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {formattedDate} {formattedTime}
                        </p>


                        <div className='d-flex align-items-center'>
                          <FaEye className='me-1' style={{ fontSize: '1rem', color: 'GrayText' }} />
                          <p className="card-text my-0"
                            style={{
                              fontSize: '0.75rem',
                              color: 'GrayText',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}>
                            {post.TotalRead || 0}
                          </p>
                        </div>


                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <nav aria-label="Page navigation">
          <ul
            className="pagination justify-content-center mt-4"
            style={{
              fontSize: '0.875rem',
            }}
          >
            {currentPage > 1 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  style={{
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    color: '#5c3f97',
                  }}
                >
                  &laquo;
                </button>
              </li>
            )}
            {pageNumbers.slice(startPage - 1, endPage).map(number => (
              <li
                key={number}
                className={`page-item ${currentPage === number ? 'active' : ''}`}
                style={{
                  fontSize: '0.875rem',
                }}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(number)}
                  style={{
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    color: currentPage === number ? '#fff' : '#5c3f97',
                    backgroundColor: currentPage === number ? '#5c3f97' : 'transparent',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {currentPage < pageNumbers.length && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  style={{
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    color: '#5c3f97',
                  }}
                >
                  &raquo;
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default BlogList;
