import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import sap1 from '../images/istock/i7.jpg';
import sap2 from '../images/istock/i9.jpg';
import sap3 from '../images/istock/i5.jpg';

const Carousel = () => {
    useEffect(() => {
        const carousel = document.getElementById('carouselExampleIndicators');
        const bootstrapCarousel = new window.bootstrap.Carousel(carousel, {
            interval: 5000,
            wrap: true,
            ride: "carousel"
        });

        return () => {
            bootstrapCarousel.dispose();
        };
    }, []);

    return (
        <div className="container-fluid px-0">
            <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
                style={{ width: '100%', overflow: 'hidden' }}
            >
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div style={{ position: 'relative', height: '100%', maxWidth: 'none' }}>
                            <img
                                className="d-block w-100"
                                src={sap1}
                                alt="First slide"
                                style={{ objectFit: 'cover', height: '90vh', maxWidth: 'none', filter: 'brightness(50%)' }}
                            />
                            <div
                                className="carousel-caption p-5"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    zIndex: 1,
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <h5 className='mb-md-4' style={{ fontSize: 'calc(1.5rem + 1vw)' }}>BAŞARI YOLUNDA BİRLİKTE İLERLEYELİM!</h5>
                                <p className='px-md-5 mx-md-5' style={{ fontSize: 'calc(1rem + 0.5vw)' } }>SAP ABAP çözümlerimizle, iş ortaklarımızla güçlü kurumsal bağlar kuruyoruz. Birlikte çalışarak, iş süreçlerinizi optimize ediyor, performansınızı artırıyor ve hedeflerinize güvenle ulaşmanızı sağlıyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div style={{ position: 'relative', height: '100%', maxWidth: 'none' }}>
                            <img
                                className="d-block w-100"
                                src={sap2}
                                alt="Second slide"
                                style={{ objectFit: 'cover', height: '90vh', maxWidth: 'none', filter: 'brightness(50%)' }}
                            />
                            <div
                                className="carousel-caption p-5"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    zIndex: 1,
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <h5 className='mb-md-4' style={{ fontSize: 'calc(1.5rem + 1vw)' }}>SAP YAZILIMI İLE PERFORMANSINIZI YÜKSELTİN</h5>
                                <p className='px-md-5 mx-md-5' style={{ fontSize: 'calc(1rem + 0.5vw)' }}>SAP, finansal süreçlerinizi optimize ederken, işletme performansınızı da zirveye taşıyor. Verimliliği artırın, kararlarınızı güçlendirin ve geleceğinizi güvence altına alın!
</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div style={{ position: 'relative', height: '100%', maxWidth: 'none' }}>
                            <img
                                className="d-block w-100"
                                src={sap3}
                                alt="Third slide"
                                style={{ objectFit: 'cover', height: '90vh', maxWidth: 'none', filter: 'brightness(50%)' }}
                            />
                            <div
                                className="carousel-caption p-5"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    zIndex: 1,
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <h5 className='mb-md-4' style={{ fontSize: 'calc(1.5rem + 1vw)' }}>İŞ SÜREÇLERİNİZİ KOLAYLAŞTIRIN</h5>
                                <p className='px-md-5 mx-md-5' style={{ fontSize: 'calc(1rem + 0.5vw)' }}>Modern teknolojiyle iş süreçlerinizi basitleştirin ve verimliliği artırın. SAP ERP ile tüm operasyonlarınızı tek bir platformda yönetin ve işletme verimliliğinizi maksimize edin.
</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default Carousel;
