import React from 'react';
import bg from '../images/istock/i2.jpg';
import sap1 from '../images/istock/i4.jpg';
import sap2 from '../images/istock/i11.jpg';
import { FaRegStar } from "react-icons/fa";
import { IoChatbubblesOutline } from "react-icons/io5";
import { GoInbox } from "react-icons/go";
import { AiOutlineLike } from "react-icons/ai";
import ChangeTitle from '../ChangeTitle';
const About = () => {
    ChangeTitle("Hakkımızda | Forcode");
    const IconCard = ({ icon: Icon, title, text }) => {
        return (
            <div className='col-lg-3 col-md-6 col-sm-6 mt-5 d-flex flex-column justify-content-center align-items-center h-100 px-4'>
                <Icon size="30%" color="#b57edc" />
                <p className='text-center mb-1' style={{ color: 'black' }}>{title}</p>
                <p className='text-center' style={{ color: 'GrayText', fontSize: '14px' }}>{text}</p>
            </div>
        );
    };
    const Content = () => {
        return (
            <div className='mt-5 pt-5' style={{ backgroundColor: 'white' }}>
                <div className='container p-5'>
                    <div className="row mx-0 mx-md-5">
                        <div className="col-12  px-md-4">
                            <div className="text-white p-md-5 p-4" style={{ backgroundColor: '#2a1b4a', borderTopLeftRadius: '2em' }}>
                                <h2>ÇIKIŞ NOKTASI</h2>
                                <p>
                                    SAP ABAP danışmanlık çözümleri sunmak amacıyla 2022 yılında kurulan Forcode Bilişim Hizmetleri, dinamik ve yetenekli kadrosuyla dikkat çekmektedir. Şirket, firmaların iş süreçlerini optimize etmelerine ve verimliliklerini artırmalarına yardımcı olmak için özelleştirilmiş SAP ABAP çözümleri sunar. Forcode Bilişim Hizmetleri, müşteri ihtiyaçlarına özel geliştirmeler yaparak, işletmelerin teknolojik altyapılarını güçlendirir ve iş süreçlerini daha verimli hale getirir. Deneyimli ekibimiz, SAP projelerinde kapsamlı bir bilgi birikimine sahip olup, müşteri memnuniyetini her zaman ön planda tutar. Yenilikçi yaklaşımımız ve uzman ekibimizle, müşterilerimize en uygun ve etkili çözümleri sunmayı hedeflemekteyiz.
                                </p>
                            </div>
                            <div className="text-white p-md-5 p-4" style={{ backgroundColor: '#7f6ea3' }}>
                                <h2>FAYDA</h2>
                                <p>
                                    Forcode Bilişim Hizmetleri, SAP ABAP danışmanlık hizmetleri sunan ve R/3 ve S/4HANA projelerinde uzmanlaşmış müşteri odaklı bir firmadır. Forcode, bilgi birikimi ve sektörel deneyimiyle oluşturduğu katma değerli çözümleri, sektör bazlı ihtiyaçlarla bütünleştirerek farklılaştırır. Vizyonu ve teknolojik yetkinliğiyle şirket kültürü olarak benimsendiğinde başarılı olabilen projelere dair bir bakış açısı sunan Forcode, müşteri odaklı çalışmak isteyen şirketler için bu altyapıyı sağlar. Müşteriye her kanaldan ve yöntemle ulaşmanın kaçınılmaz olduğu günümüz dünyasında, sürekli değişen müşteri ihtiyaçları ve beklentileri Forcode çözümleriyle fırsata dönüşüyor.
                                </p>
                            </div>
                            <div className="text-white p-md-5 p-4" style={{ backgroundColor: '#a999c7', borderBottomRightRadius: '2em' }}>
                                <h2>AMAÇ</h2>
                                <p>
                                    Forcode Bilişim Hizmetleri olarak amacımız, müşterilerimizin SAP ABAP danışmanlığı ihtiyaçlarını en üst düzeyde karşılamaktır. Müşteri odaklı yaklaşımımızla, işletmelerin iş süreçlerini optimize etmelerine, verimliliklerini artırmalarına ve teknolojik altyapılarını güçlendirmelerine yardımcı oluyoruz. Amacımız, müşterilerimizin özel gereksinimlerine uygun, yenilikçi ve etkili çözümler sunarak, onların iş hedeflerine ulaşmalarını sağlamaktır. Müşteri memnuniyetini her zaman ön planda tutarak, uzun vadeli ve sürdürülebilir iş ilişkileri kurmayı hedefliyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={{ backgroundColor: '#F5F5F5', marginTop: '80px' }}>
            <div
                className='w-100 position-relative'
                style={{
                    maxHeight: '300px',
                    overflow: 'hidden',
                }}
            >
                <img
                    className="d-block w-100"
                    src={bg}
                    alt="Sap Logo"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
                />

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
                        zIndex: 1,
                    }}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 p-3'>
                        <div className='col-12 col-md-8'>
                            <h5 className='text-white text-center' style={{ fontSize: 'calc(0.8rem + 0.5vw)', padding: '0 1rem' }}>
                                Teknolojimizin sunduğu geniş yelpazeyi kullanarak, müşterilerimizin en zorlu sorunlarına etkili ve yaratıcı çözümler sunuyoruz
                            </h5>
                        </div>

                    </div>
                </div>

            </div>

            <div className='container p-5'>
                <div className='row my-5'>

                    <div className='col-lg-6 col-md-12 col-sm-12'  >

                        <div className="section-title mx-md-5 mb-5">
                            <h1 className="title" style={{ color: '#5c3f97'}}>Forcode Bilişim Hizmetleri</h1>

                            <p className="text mt-4" style={{ color: 'GrayText', paddingRight: '3em' }} >
                                Forcode Bilişim Hizmetleri, SAP ABAP danışmanlık hizmetlerinde uzmanlaşmış, sektördeki en güvenilir
                                ve yenilikçi danışmanlık firmalarından biridir. Dijital dönüşüm çağında, işletmelerin rekabet avantajı
                                elde etmeleri ve süreçlerini optimize etmeleri için kritik öneme sahip olan SAP ABAP çözümleri konusunda
                                derin bir bilgi birikimine sahibiz. Müşterilerimizin dijital dönüşüm yolculuklarında yanlarında yer alarak,
                                iş süreçlerini daha verimli hale getiriyor ve onların iş hedeflerine ulaşmalarına yardımcı oluyoruz.
                            </p>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', overflow: 'hidden' }}>
                        <img
                            className="d-block w-100"
                            src={sap1}
                            alt="Sap Logo"
                        />
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'white' }}>

                <div className='container p-5' >
                    <div className='row my-5'>
                        <div className='col-lg-6 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', overflow: 'hidden' }}>
                            <img
                                className="d-block w-100"
                                src={sap2}
                                alt="Sap Logo"
                            />
                        </div>

                        <div className='col-lg-6 col-md-12 col-sm-12'  >
                            <div className="section-title mx-md-5 ">

                                <h4 className="title mt-0" style={{ color: '#5c3f97' }}>Neden Forcode Bilişim Hizmetleri?</h4>

                                <p className="text " style={{ color: 'GrayText' }} >
                                    Uzmanlık ve Deneyim: SAP ABAP'daki yılların verdiği deneyimle, size özel çözümler sunuyoruz. Derin bilgi birikimimizi işletmenizin hizmetine sunuyoruz.
                                </p>
                                <p className="text " style={{ color: 'GrayText' }} >
                                    Müşteri Odaklı Yaklaşım: İhtiyaçlarınıza uygun özel çözümler geliştiriyor, iş hedeflerinize ulaşmanıza yardımcı oluyoruz.
                                </p>
                                <p className="text " style={{ color: 'GrayText' }} >
                                    İnovasyon ve Kalite: En son teknolojileri takip ederek yüksek kaliteli ve yenilikçi çözümler sunuyoruz. İnovasyon, hizmetlerimizin kalitesini artırmanın temelidir.
                                </p>
                                <p className="text " style={{ color: 'GrayText' }} >
                                    Güvenilirlik ve Profesyonellik: İş etiği ve profesyonellik çerçevesinde, müşteri memnuniyetini ön planda tutuyoruz. Güvenilirlik ve profesyonellik, ilişkilerimizin temel taşlarıdır.
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='container p-5 pb-5'>
                <div className='mx-5 '>

                    <div className='row d-flex flex-row   '>
                        <IconCard
                            icon={FaRegStar}
                            title="Finans Sektörü"
                            text="SAP ABAP çözümleriyle operasyonel verimlilik artırdık ve müşteri memnuniyetini iyileştirdik."
                        />
                        <IconCard
                            icon={IoChatbubblesOutline}
                            title="Üretim Sektörü"
                            text="Üretim süreçlerini dijitalleştirip maliyet tasarrufu sağladık, rekabet avantajı kazandırdık."
                        />
                        <IconCard
                            icon={GoInbox}
                            title="Perakende Sektörü"
                            text="Satış ve stok yönetimini iyileştirip, iş kararlarını daha hızlı ve doğru almalarını sağladık."
                        />
                        <IconCard
                            icon={AiOutlineLike}
                            title="Geleceğe Yönelik Taahhüdümüz"
                            text="Sürekli gelişim ve inovasyonla, en iyi çözümleri sunmaya devam edeceğiz."
                        />
                   
                    </div>

                </div>



            </div>

            <Content />

        </div>
    );


};

export default About;
