import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import loginLogo from '../images/forcode_reverse.png';
import { BrowserRouter as Router, Route, Link, useLocation } from "react-router-dom";
import { auth } from '../config';
import { signOut } from 'firebase/auth';

const Header = () => {
  const location = useLocation();

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log('Başarıyla çıkış yapıldı.');
    }).catch((error) => {
      console.error('Hata:', error.message);
    });
  };

  return (
    <header className="bg-light py-0" style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000, height: '80px' }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 py-2 d-flex ">
            <a href="/">
              <img
                className="image img-fluid"
                src={loginLogo}
                alt="avatar"
                style={{  maxWidth: '100%',
                  minWidth:'110px',  
                  maxHeight: '60px', 
                  height: 'auto', 
                  width: 'auto', 
                  objectFit: 'contain'}}
              />
            </a>
          </div>
          <div className="col-10 d-flex align-items-center mx-0 px-0">
            <nav className="navbar navbar-expand-md navbar-light w-100">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ marginLeft: 'auto' }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse bg-light" id="navbarNav" style={{ position: 'relative', zIndex: 1000, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <ul className="navbar-nav ml-auto">
                  <MenuItem title="Hakkımızda" dropdown>
                    <DropdownItem title="Şirket" link="/hakkımızda" />
                    <DropdownItem title="Ekip" link="/ekip" />
                  </MenuItem>
                  <MenuItem title="Müşterilerimiz" link="/musterilerimiz" />
                  <MenuItem title="Blog" link="/blog" />
                  <MenuItem title="İletişim" link="/iletisim" />
                  {location.pathname === '/admin-panel' && (
                    <li className="nav-item">
                      <button className="nav-link text-center" style={{color:'#5c3f97', fontWeight:'bold'}} onClick={handleLogout}>
                        Çıkış Yap
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

const MenuItem = ({ title, link, children, dropdown }) => {
  return (
    <li className={`nav-item ${dropdown ? 'dropdown' : ''}`}>
      {link ? (
        <a className="nav-link text-center" href={link} style={{color:'#5c3f97', fontWeight:'bold'}}>
          {title}
        </a>
      ) : (
        <a
          className="nav-link dropdown-toggle text-center"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{color:'#5c3f97', fontWeight:'bold'}}
        >
          {title}
        </a>
      )}
      {dropdown && (
        <div className="dropdown-menu" aria-labelledby="navbarDropdown" >
          {children}
        </div>
      )}
    </li>
  );
};

const DropdownItem = ({ title, link }) => {
  return (
    <a className="dropdown-item" href={link} style={{backgroundColor:'white', color:'#5c3f97'}}>
      {title}
    </a>
  );
};

export default Header;
