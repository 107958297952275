import React from 'react';
import bg from '../images/istock/i6.jpg';
import abdullah from '../images/abdullah.png';
import kaan from '../images/kaan.jpg';
import ilker from '../images/ilker.png';
import ersin from '../images/ersin.jpg';
import ChangeTitle from '../ChangeTitle';
const Profiles = () => {

    ChangeTitle("Ekip | Forcode");

    return (
        <div style={{ backgroundColor: '#F5F5F5', marginTop: '80px' }}>

            <div
                className='w-100 position-relative'
                style={{
                    maxHeight: '300px',
                    overflow: 'hidden',
                }}
            >
                <img
                    className="d-block w-100"
                    src={bg}
                    alt="Sap Logo"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
                />

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
                        zIndex: 1,
                    }}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 p-3'>
                        <div className='col-12 col-md-8'>
                            <h1 className='text-white text-center' style={{ fontSize: 'calc(1.2rem + 1vw)' }}>
                                EKİP
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="container  px-md-5">
                <div className='row mx-md-4 p-5 '>

                <div className='col-lg-6 col-md-12 col-sm-12 py-md-5 '>
                        <div className='px-md-5' >
                            <div className="section-title">
                                <div className='col-lg-12 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', height: 'auto', overflow: 'hidden' }}>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={abdullah}
                                        alt="Sap Logo"
                                        style={{ borderRadius: '30px', }}
                                    />
                                </div>
                                <h3 className="title mt-3" style={{ color: '#5c3f97' }} >Abdullah Atan</h3>
                                <p className="title mb-3" style={{ color: 'GrayText', fontWeight: 'bold' }} >Co-Founder</p>
                                <p className="text mt-0 pt-0" >
                                    Abdullah ATAN, SAP ABAP geliştirmeleri alanında 10 yılı aşkın deneyimi ile
                                    çeşitli sektörlerdeki seçkin R/3 ve S/4HANA projelerinde Senior ve Lead Danışman olarak görev almıştır.
                                    FI, CO, FM, MM, SD, HR ve FICA başta olmak üzere birçok SAP modülünde ABAP Geliştirme Uzmanı olarak görev alarak,
                                    bu modüllerde derin bilgi ve ileri düzeyde beceri deneyimi kazanmıştır.
                                    SAP HANA'nın getirdiği yenilikler olan CDS (Core Data Services), AMDP (ABAP Managed Database Procedures),
                                    OData servisler, Hana Databese ve Yüksek Performanslı Uygulamaların yanında, Nesne Yönelimli Programlama (OOP) ve
                                    Design Pattern kullanımı konularında da uzmanlaşmıştır.
                                    Toplu Ödeme Sisteme(TOS), Masraf/Avans/Seyahat Takibi, Zimmet Takibi, Enflasyon Yeniden Değerleme Çözümleri ve
                                    e-Beyanname gibi ürünlerin geliştirilmesine de imza atmıştır.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6 col-md-12 col-sm-12 py-md-5 '>
                        <div className='px-md-5' >
                            <div className="section-title">
                                <div className='col-lg-12 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', height: 'auto', overflow: 'hidden' }}>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={ersin}
                                        alt="Sap Logo"
                                        style={{ borderRadius: '30px', }}
                                    />
                                </div>
                                <h3 className="title mt-3" style={{ color: '#5c3f97' }} >Ersin Tunç</h3>
                                <p className="title mb-3" style={{ color: 'GrayText', fontWeight: 'bold' }} >Co-Founder</p>
                                <p className="text mt-0 pt-0" >
                                    Ersin TUNÇ, 2014 yılında Trakya Üniversitesi Bilgisayar Teknolojisi ve Bilişim Sistemleri (CTIS)
                                    bölümünden mezun olmuş, sektörde 10 yılı aşkın deneyime sahip, SAP projelerinde yöneticilik ve
                                    danışmanlık yapan deneyimli bir SAP ABAP danışmanıdır. Özellikle Fintech, SAP ve Non-SAP sistem
                                    entegrasyonları, mimari tasarım ve performans optimizasyonu konularında uzmanlaşmıştır. SAP FI,
                                    FM, CO, PS modülleri başta olmak üzere birçok modülde ABAP desteği sunan Ersin, bankacılık
                                    entegrasyonları (DBS, OBS), e-beyanname ve iade KDV gibi projelerin teknik tasarımlarını yapmış
                                    ve bu projelere liderlik etmiştir. Hizmet verdiği firmalarda proje teknik liderliği, takım liderliği
                                    ve yazılım grup yöneticiliği pozisyonlarında görev almıştır. 2022 yılında ekip arkadaşlarıyla birlikte
                                    kurduğu Forcode Bilişim Hizmetleri’nde Genel Müdür olarak görev yapmaktadır.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6 col-md-12 col-sm-12 py-md-5 '>
                        <div className='px-md-5' >
                            <div className="section-title">
                                <div className='col-lg-12 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', height: 'auto', overflow: 'hidden' }}>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={ilker}
                                        alt="Sap Logo"
                                        style={{ borderRadius: '30px', }}
                                    />
                                </div>
                                <h3 className="title mt-3" style={{ color: '#5c3f97' }} >İlker Ahmet Özbayram</h3>
                                <p className="title mb-3" style={{ color: 'GrayText', fontWeight: 'bold' }} >Co-Founder</p>
                                <p className="text mt-0 pt-0" >
                                    İlker Ahmet ÖZBAYRAM, 2017 yılında Okan Üniversitesi Bilişim Sistemleri ve Teknolojileri
                                    bölümünden mezun olduktan sonra, SAP ABAP alanında uzmanlaşarak kariyerine adım attı. 8 yılı
                                    aşkın süredir FI-CO, MM, SD, PP, HR gibi çeşitli SAP modüllerinde ABAP geliştirme üzerine çalışmış
                                    ve bu süreçte takım liderliği ve proje teknik liderliği gibi önemli pozisyonlarda görev almıştır.
                                    2022 yılında, ekip arkadaşlarıyla birlikte FORCODE Bilişim Hizmetleri'ni kuran ÖZBAYRAM, halen bu
                                    şirkette ABAP Danışmanı olarak çalışmakta ve Satış ve Pazarlama Müdürlüğü görevini yürütmektedir.
                                </p>
                            </div>
                        </div>
                    </div>


              

                    <div className='col-lg-6 col-md-12 col-sm-12 py-md-5 '>
                        <div className='px-md-5' >
                            <div className="section-title">
                                <div className='col-lg-12 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', height: 'auto', overflow: 'hidden' }}>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={kaan}
                                        alt="Sap Logo"
                                        style={{ borderRadius: '30px', }}
                                    />
                                </div>
                                <h3 className="title mt-3" style={{ color: '#5c3f97' }} >Kaan Can Ateş</h3>
                                <p className="title mb-3" style={{ color: 'GrayText', fontWeight: 'bold' }} >Co-Founder</p>
                                <p className="text mt-0 pt-0" >
                                    Kaan Can ATEŞ, Yeditepe Üniversitesi Bilişim Sistemleri ve Teknolojileri mezunudur. 8 yıl
                                    FinPro Danışmanlık Yazılım A.Ş.'de çalışmış ve son 1 yılında ABAP Team Lead olarak görev yapmıştır.
                                    Aralık 2022'den itibaren Forcode Bilişim Hizmetleri'nde Kurucu Ortak olarak çalışmaktadır. ABAP ve Fiori
                                    konularında uzmanlaşmış olup, birçok projede yer almıştır. Teknolojiye olan tutkusu ve müşteri odaklı
                                    yaklaşımı ile kaliteli hizmet sunmayı hedefler.
                                </p>
                            </div>
                        </div>
                    </div>

             



                </div>
            </div>

        </div>
    );


};

export default Profiles;
