import React, { useState } from 'react';
import loginLogo from '../images/logorb.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../config';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/admin-panel");
        console.log(user);

        setTimeout(() => {
          signOut(auth)
            .then(() => {
              console.log("Oturum kapatıldı");
              navigate("/login");
            })
            .catch((error) => {
              console.error("Oturum kapatılırken hata oluştu:", error);
            });
        }, 3600000);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage); // Hata mesajını state'e atayın
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="row justify-content-center w-100">
        <div className="col-md-6">
          <div className="card shadow-lg border-0" style={{ borderRadius: '8px' }}>
            <div className="card-body p-4">
              <div className="text-center mb-4">
                <img
                  src={loginLogo}
                  alt="Avatar"
                  className="img-fluid"
                  style={{ maxWidth: '40%', height: 'auto' }}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">E-posta</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    style={{
                      border: '2px solid #ced4da',
                      borderRadius: '8px',
                      padding: '12px',
                      fontSize: '16px'
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Şifre</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{
                      border: '2px solid #ced4da',
                      borderRadius: '8px',
                      padding: '12px',
                      fontSize: '16px'
                    }}
                  />
                </div>
                {error && <p className="text-danger text-center" style={{ fontWeight: 'bold', fontSize: '14px' }}>{error}</p>}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    style={{
                      backgroundColor: '#007bff',
                      borderColor: '#007bff',
                      borderRadius: '8px',
                      padding: '10px 20px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      transition: 'background-color 0.3s, border-color 0.3s'
                    }}
                  >
                    Giriş Yap
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
