import React, { useState, useRef, useMemo, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaEye, FaPencilAlt, FaTrash, FaEnvelopeOpen, FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../config';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChangeTitle from '../ChangeTitle';
import hljs from 'highlight.js';
import "highlight.js/styles/github.css";
import abap from 'highlightjs-sap-abap/src/abap';


const AdminPanel = () => {
  const [posts, setPosts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');
  const [newImage, setNewImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMessagePage, setCurrentMessagePage] = useState(1);
  const postsPerPage = 5;
  const maxPageNumbersToShow = 5;
  const [selectedMessage, setSelectedMessage] = useState(null);

  const navigate = useNavigate();
  const quillRef = useRef(null);
  hljs.registerLanguage('abap', abap);
  useEffect(() => {

    fetchMessages();
    fetchPosts();
  }, []);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        navigate("/admin-panel");
      } else {
        setUser(null);
        navigate("/login");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const openMessageDetail = (message) => {
    setSelectedMessage(message);
    window.$('#messageModal').modal('show');
  };


  const handleImageUpload = () => { 
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setNewImage(reader.result); // Görsel URL'sini state'e kaydediyoruz
          const range = quillRef.current.getEditor().getSelection();
          quillRef.current.getEditor().insertEmbed(range.index, 'image', reader.result);
          quillRef.current.getEditor().setSelection(range.index + 1);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const modules = useMemo(() => ({
        syntax: {
          highlight: text => {
            // Eğer ABAP dilini otomatik tespit etmek isterseniz:
            const detectedLanguage = hljs.highlightAuto(text, ['abap']).value;
            return detectedLanguage;
          }
      
    },
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'code-block',],
        [{ 'color': [] }, { 'background': [] }], 
      ],
      handlers: {
        image: handleImageUpload,
      },
      clipboard: {
        matchVisual: false, 
      }
    }
  }), []);

  const formats = [
    'header',
    'font',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'link', 'image', 'code-block'
  ];

  ChangeTitle("Admin Panel | Forcode");

  const fetchPosts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Articles'));
      const postsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setPosts([...postsList].sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)));
      setLoading(false);
    } catch (error) {
      console.error('Veriler çekilirken hata oluştu:', error);
      setError('Veriler çekilirken bir hata oluştu.');
      setLoading(false);
    }
  };

  const fetchMessages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'Messages'));
      const messagesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages([...messagesList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setLoading(false);
    } catch (error) {
      console.error('Veriler çekilirken hata oluştu:', error);
      setError('Veriler çekilirken bir hata oluştu.');
      setLoading(false);
    }
  };

  const toggleReadStatus = async (id) => {
    // Öncelikle mesajı güncelle
    const updatedMessages = messages.map(message => {
      if (message.id === id) {
        return { ...message, Read: message.Read === 'false' ? 'true' : 'false' };
      }
      return message;
    });

    setMessages(updatedMessages);

    // Daha sonra veritabanında güncelleme yap
    try {
      const messageDoc = doc(db, 'Messages', id);
      const newStatus = updatedMessages.find(message => message.id === id).Read;
      await updateDoc(messageDoc, { Read: newStatus });
      console.log(`Mesaj ${id} başarıyla güncellendi.`);
    } catch (error) {
      console.error(`Mesaj ${id} güncellenirken bir hata oluştu:`, error);
    }
  };

  const handleAddPost = async (e) => {
    e.preventDefault();
    if (!newTitle || !newContent || !bannerImage) {
      setError('Başlık, içerik veya kapak fotoğrafı boş olamaz.');
      return;
    }

    const newPost = {
      Title: newTitle,
      Content: newContent,
      Image: bannerImage,
      Author: user.email,
      TotalRead: 0,
      CreatedAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, 'Articles'), newPost);

      setPosts(prevPosts => [...prevPosts, { ...newPost, id: docRef.id }]);
      setNewTitle('');
      setNewContent('');
      setBannerImage('');
      setError('');
      setCurrentPage(1);
      toast.success('Yeni blog yazısı eklendi!');
    } catch (error) {
      console.error('Post eklenirken hata oluştu:', error);
      setError('Post eklenirken bir hata oluştu: ' + error);
      toast.error('Post eklenirken hata oluştu.');
    }
  };

  const handleEditPost = (post) => {
    setEditMode(true);
    setSelectedPost(post);
    setNewTitle(post.Title);
    setNewContent(post.Content);
    setBannerImage(post.Image || ''); 
    setError('');
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();
    if (!newTitle || !newContent) {
      setError('Başlık ve içerik boş olamaz.');
      return;
    }

    try {
      const postRef = doc(db, 'Articles', selectedPost.id);
      await updateDoc(postRef, {
        Title: newTitle,
        Content: newContent,
        Image: bannerImage, 
        UpdatedAt: new Date(), 
      });

      const updatedPosts = posts.map(post =>
        post.id === selectedPost.id ? { ...post, Title: newTitle, Content: newContent, Image: newImage } : post
      );
      setPosts(updatedPosts);

      setEditMode(false);
      setSelectedPost(null);
      setNewTitle('');
      setNewContent('');
      setBannerImage('');
      setError('');
      toast.success('Blog yazısı güncellendi!');
    } catch (error) {
      console.error('Post güncellenirken hata oluştu:', error);
      setError('Post güncellenirken bir hata oluştu: ' + error);
      toast.error('Post güncellenirken hata oluştu.');
    }
  };

  const handleDeletePost = async (id) => {
    if (window.confirm('Bu blog yazısını silmek istediğinizden emin misiniz?')) {
      try {
        const postRef = doc(db, 'Articles', id);
        await deleteDoc(postRef);

        const updatedPosts = posts.filter(post => post.id !== id);
        setPosts(updatedPosts);
        setError('');
        toast.success('Blog yazısı silindi!');
      } catch (error) {
        console.error('Post silinirken hata oluştu:', error);
        setError('Post silinirken bir hata oluştu.');
        toast.error('Post silinirken hata oluştu.');
      }
    }
  };

  const handleDeleteMessage = async (id) => {
    if (window.confirm('Bu mesajı silmek istediğinizden emin misiniz?')) {
      try {
        const postRef = doc(db, 'Messages', id);
        await deleteDoc(postRef);

        const updatedMessages = messages.filter(message => message.id !== id);
        setMessages(updatedMessages);
        setError('');
        toast.success('Mesaj silindi!');
      } catch (error) {
        console.error('Mesaj silinirken hata oluştu:', error);
        setError('Mesaj silinirken bir hata oluştu.');
        toast.error('Mesaj silinirken hata oluştu.');
      }
    }
  };

  const handleContentChange = (content, delta, source, editor) => {
    setNewContent(editor.getHTML());
  };

  const openPostDetail = (post) => {
    navigate(`/${post.id}`);
  };

  const sortedPosts = posts.sort((a, b) => b.CreatedAt - a.CreatedAt);
  const sortedMessages = messages.sort((a, b) => b.CreatedAt - a.CreatedAt);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  const indexOfLastMessage = currentMessagePage * postsPerPage;
  const indexOfFirstMessage = indexOfLastMessage - postsPerPage;
  const currentMessages = sortedMessages.slice(indexOfFirstMessage, indexOfLastMessage);

  const pageNumbers = [];
  const pageMessageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  for (let i = 1; i <= Math.ceil(messages.length / postsPerPage); i++) {
    pageMessageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleMessagePageChange = (pageNumber) => {
    setCurrentMessagePage(pageNumber);
  };

  const renderPageNumbers = () => {
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, pageNumbers.length);

    return (
      <nav aria-label="Page navigation">
        <ul
          className="pagination justify-content-center mt-4"
          style={{ fontSize: '0.875rem' }}
        >
          {currentPage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                }}
              >
                &laquo;
              </button>
            </li>
          )}
          {pageNumbers.slice(startPage - 1, endPage).map(number => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? 'active' : ''}`}
              style={{ fontSize: '0.875rem' }}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(number)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                  backgroundColor: currentPage === number ? '#5c3f97' : 'transparent',
                  color: currentPage === number ? 'white' : '#5c3f97',
                }}
              >
                {number}
              </button>
            </li>
          ))}
          {currentPage < pageNumbers.length && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                }}
              >
                &raquo;
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };


  const renderMessagePageNumbers = () => {
    const startPage = Math.max(1, currentMessagePage - Math.floor(maxPageNumbersToShow / 2));
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, pageMessageNumbers.length);

    return (
      <nav aria-label="Page navigation">
        <ul
          className="pagination justify-content-center mt-4"
          style={{ fontSize: '0.875rem' }}
        >
          {currentMessagePage > 1 && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handleMessagePageChange(currentMessagePage - 1)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                }}
              >
                &laquo;
              </button>
            </li>
          )}
          {pageMessageNumbers.slice(startPage - 1, endPage).map(number => (
            <li
              key={number}
              className={`page-item ${currentMessagePage === number ? 'active' : ''}`}
              style={{ fontSize: '0.875rem' }}
            >
              <button
                className="page-link"
                onClick={() => handleMessagePageChange(number)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                  backgroundColor: currentMessagePage === number ? '#5c3f97' : 'transparent',
                  color: currentMessagePage === number ? 'white' : '#5c3f97',
                }}
              >
                {number}
              </button>
            </li>
          ))}
          {currentMessagePage < pageMessageNumbers.length && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handleMessagePageChange(currentMessagePage + 1)}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 12px',
                  color: '#5c3f97',
                }}
              >
                &raquo;
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };


  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    loading ? <div>Yükleniyor...</div> : <div className="container mt-5">
      <ToastContainer />
      <h2 className="text-center mb-4">Admin Paneli</h2>

      <div className="mb-4 row container mx-0">
        <div className="card col-md-6">
          <div className="card-header bg-secondary text-white">
            <h3 className="mb-0">Önceden Yazılmış Blog Yazıları</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead className="table-dark">
                  <tr>
                    <th className='text-center'>Başlık</th>
                    <th className='text-center'>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map(post => (
                    <tr key={post.id}>
                      <td className='text-center' style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '150px'
                      }}>{post.Title}</td>
                      <td className='text-center'>
                        <button
                          className="btn btn-primary btn-sm me-2 custom-btn"
                          onClick={() => openPostDetail(post)}
                        >
                          <FaEye className="" />
                        </button>
                        <button
                          className="btn btn-warning btn-sm me-2 custom-btn"
                          onClick={() => handleEditPost(post)}
                        >
                          <FaPencilAlt className="" />
                        </button>
                        <button
                          className="btn btn-danger btn-sm custom-btn"
                          onClick={() => handleDeletePost(post.id)}
                        >
                          <FaTrash className="" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {renderPageNumbers()}

          </div>
        </div>
        <div className="card col-md-6">
          <div className="card-header bg-secondary text-white">
            <h3 className="mb-0">Mesajlar</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead className="table-dark">
                  <tr>
                    <th className='text-center'>İsim</th>
                    <th className='text-center'>Tarih</th>
                    <th className='text-center'>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMessages.map(message => {
                    const createdAtDate = new Date(message.CreatedAt.seconds * 1000);
                    const formattedDate = createdAtDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric' });
                    const formattedTime = createdAtDate.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });

                    return (
                      <tr key={message.id}>
                        <td className='text-center'
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px'

                          }}
                        >{message.Name}</td>

                        <td className='text-center'

                        >{formattedDate} - {formattedTime}</td>
                        <td className='text-center'>
                          <button
                            className="btn btn-primary btn-sm me-2 custom-btn"
                            onClick={() => openMessageDetail(message)}
                          >
                            <FaEye className="" />
                          </button>
                          <button
                            className="btn btn-danger btn-sm custom-btn me-2"
                            onClick={() => handleDeleteMessage(message.id)}
                          >
                            <FaTrash className="" />
                          </button>
                          <button
                            className={`btn btn-sm ${message.Read === 'true' ? 'btn-success' : 'btn-info'} custom-btn`}
                            onClick={() => toggleReadStatus(message.id)}
                          >
                            {message.Read === 'true' ? <FaEnvelopeOpen className="" /> : <FaEnvelope className="" />}
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation">
              {renderMessagePageNumbers()}
            </nav>
            <div className="modal fade" id="messageModal" tabIndex="-1" aria-labelledby="messageModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  {
                    <div className="modal-body">
                      {selectedMessage ?
                        <div>

                          <div className="modal-header">
                            <h5 className="modal-title" id="messageModalLabel">{`${selectedMessage.Name} -`}   </h5>
                            <a href={`mailto:${selectedMessage.Email}`} className="text mx-1" style={{ color: 'black' }}>
                             { ` ${selectedMessage.Email}`}
                            </a>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>

                          <div className="modal-body">
                            {selectedMessage.Message}
                          </div>
                        </div>


                        : 'Seçili mesaj bulunmuyor.'}
                    </div>
                  }



                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="mt-4 container">
        <div className="card">
          <div className="card-header bg-secondary text-white">
            <h3 className="mb-0">{editMode ? 'Blog Yazısını Güncelle' : 'Yeni Blog Yazısı Ekle'}</h3>
          </div>
          <div className="card-body">
            <form onSubmit={editMode ? handleUpdatePost : handleAddPost}>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">Başlık</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </div>
              <div className="mb-3" >
                <label htmlFor="content" className="form-label">İçerik</label>
                <ReactQuill
                  ref={quillRef}
                  value={newContent}
                  onChange={handleContentChange}
                  modules={modules}
                  style={{maxHeight:'500px', overflowY:'auto'}}
                 // formats={formats}
                />
                
              </div>
              <div className="mb-3">
                <label htmlFor="image" className="form-label">Kapak Görseli</label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = () => setBannerImage(reader.result);
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {bannerImage && <img src={bannerImage} alt="Kapak Görseli" className="mt-3" style={{ maxWidth: '100%', maxHeight: '200px' }} />}
              </div>
              {error && <p className="text-danger">{error}</p>}
              <div className="text-center">
                <button type="submit" className="btn btn-success">{editMode ? 'Güncelle' : 'Ekle'}</button>
              </div>



            </form>
          </div>
        </div>
      </div>
      <style >{`
      .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff; /* Prevent it from being transparent */
  border-bottom: 1px solid #ccc; /* Optional: better visual separation */
}
        .custom-btn {
          font-weight: 600;
          border-radius: 4px;
          border: none;
          padding: 8px 12px;
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .custom-btn:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        .custom-btn:focus {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
        }

        .btn-primary.custom-btn {
          background-color: #0056b3;
          color: #ffffff;
        }

        .btn-primary.custom-btn:hover {
          background-color: #004494;
        }

        .btn-warning.custom-btn {
          background-color: #ffcc00;
          color: #212529;
        }

        .btn-warning.custom-btn:hover {
          background-color: #e0a800;
        }

        .btn-danger.custom-btn {
          background-color: #dc3545;
          color: #ffffff;
        }

        .btn-danger.custom-btn:hover {
          background-color: #c82333;
        }

        .customButton {
  background-color: #5c3f97; /* Mert'in tercih ettiği mor renk */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px;
}

.customButton:hover {
  background-color: #4a317a; /* Hover durumunda koyu mor renk */
}
      `}</style>
    </div>
  );
};

export default AdminPanel;
