import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFound = () => {
  return (
    <div className="container text-center" style={{ marginTop: '100px' }}>
      <h1>404</h1>
      <p>Sayfa Bulunamadı</p>
      <a href="/" className="btn btn-primary">Anasayfa'ya Dön</a>
    </div>
  );
};

export default NotFound;
    