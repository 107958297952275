import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, updateDoc, addDoc, query, where } from 'firebase/firestore';
import { db } from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import abap from 'highlightjs-sap-abap/src/abap';
import axios from 'axios';

hljs.registerLanguage('abap', abap);

const PostDetail = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [user, setUser] = useState(null);
    const [allPosts, setAllPosts] = useState([]);
    const [latestPosts, setLatestPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const res = await axios.get("https://api.ipify.org/?format=json");

                setUser(null);
                const docRef = doc(db, 'Articles', postId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {

                    const postData = { id: docSnap.id, ...docSnap.data() };
                    setPost(postData);

                    if (postData.Content) {
                        const parser = new DOMParser();
                        const parsedHTML = parser.parseFromString(postData.Content, 'text/html');

                        const preElements = parsedHTML.querySelectorAll('pre.ql-syntax');

                        preElements.forEach(function (pre) {
                            const highlightedContent = hljs.highlight(pre.textContent, { language: 'abap' }).value;

                            pre.innerHTML = highlightedContent;
                        });

                        const updatedContent = new XMLSerializer().serializeToString(parsedHTML);
                        setPost({ ...postData, Content: updatedContent });

                    }

                    if (postData.Author) {
                        const docRef2 = doc(db, 'Users', postData.Author);
                        const docSnap2 = await getDoc(docRef2);

                        if (docSnap2.exists()) {
                            setUser(docSnap2.data());
                        } else {
                            console.error('Yazar bilgileri bulunamadı');
                        }
                    }

                   

                    const ipValues = {
                        CreatedAt: new Date(),
                        ipAddress: res.data,
                    };

                    const q = query(
                        collection(db, 'Articles', postId, 'ipAddress'),
                        where('ipAddress', '==', res.data)
                    );

                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                  
                    } else {
                        if (postData.TotalRead != null) { 
                            var totalRead = postData.TotalRead; 
                            totalRead++; 
    
                            await updateDoc(docRef, {
                                TotalRead: totalRead
                            });
                        } else {
                            await updateDoc(docRef, {
                                TotalRead: 1
                            });
                        }
                        const ipDoc = await addDoc(collection(db, 'Articles', postId, 'ipAddress'), ipValues);
                        
                    }


                } else {
                    setError('Sayfa bulunamadı.');
                }
            } catch (error) {
                console.error('Post çekilirken hata oluştu:', error);
                setError('Post çekilirken bir hata oluştu.');
            } finally {
                setLoading(false);
            }
        };


        const fetchAllPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Articles'));
                const postsList = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => b.CreatedAt.toDate().getTime() - a.CreatedAt.toDate().getTime());
                setAllPosts(postsList);
            } catch (error) {
                console.error('Blog yazıları çekilirken hata oluştu:', error);
                setError('Blog yazıları çekilirken bir hata oluştu.');
            }
        };

        const fetchLatestPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Articles'));
                const postsList = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .sort((a, b) => b.CreatedAt.toDate().getTime() - a.CreatedAt.toDate().getTime())
                    .slice(0, 5);
                setLatestPosts(postsList);
            } catch (error) {
                console.error('Son blog yazıları çekilirken hata oluştu:', error);
                setError('Son blog yazıları çekilirken bir hata oluştu.');
            }
        };



        fetchPost();
        fetchAllPosts();
        fetchLatestPosts();
    }, [postId]);

    if (loading) return <div style={{ height: '91vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <p className="text-center">Yükleniyor...</p>
  </div>
  ;
    if (error) return <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <p className="text-center mt-5 text-danger">{error}</p>
  </div>;

    const createdAtDate = new Date(post.CreatedAt.seconds * 1000);
    const formattedDate = createdAtDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric' });
    const formattedTime = createdAtDate.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });

    const currentIndex = allPosts.findIndex(p => p.id === postId);
    const prevPost = allPosts[currentIndex - 1];
    const nextPost = allPosts[currentIndex + 1];

    return (
        <div style={{ marginTop: '80px' }}>
            <div className="container">

                <div className="col-md-9 mb-3">
                    <article className="px-4" style={{
                        backgroundColor: '#F5F5F5',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        overflow: 'hidden'
                    }}>
                        <h1 className="post-detail-title" style={{
                            //fontSize: '3rem',
                            fontWeight: 'bold',
                            margin: '0'
                        }}>
                            {post.Title}
                        </h1 >
                        <p style={{
                            fontSize: '1rem',
                            marginBottom: '20px',
                            color: '#6c757d'
                        }}>
                            {formattedDate} - {formattedTime}
                        </p>
                    </article>
                </div>
                <div className="row">
                    <div className="col-md-9 mb-3">
                        <article className="p-4" style={{
                            backgroundColor: '#F5F5F5',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            overflow: 'hidden'
                        }}>
                            <div
                                dangerouslySetInnerHTML={{ __html: post.Content }}
                                style={{
                                    lineHeight: '1.8',
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word'
                                }}
                            />
                        </article>
                    </div>
                    <div className="col-md-3">

                        {user && (
                            <aside className="p-4 mb-2" style={{
                                backgroundColor: '#f9f9f9',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                padding: '20px',
                                display: 'flex',

                            }}>

                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <img
                                        src={user.Image}
                                        alt="Yazar Görseli"
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            marginRight: '10px'
                                        }}
                                    />
                                    <span style={{
                                        fontSize: '1rem',
                                        color: '#333'
                                    }}>{user.Name}</span>
                                </div>
                            </aside>
                        )}




                        <aside className=" p-4" style={{
                            backgroundColor: '#f9f9f9',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            overflow: 'hidden',
                            padding: '20px'
                        }}>
                            <h2 style={{
                                fontSize: '1.5rem',
                                color: '#5c3f97',
                                marginBottom: '20px'
                            }}>
                                Son Blog Yazıları
                            </h2>
                            <ul style={{
                                listStyleType: 'none',
                                padding: '0',
                                margin: '0'
                            }}>
                                {latestPosts.map(post => (
                                    <li key={post.id} style={{
                                        fontSize: '1.1rem',
                                        color: '#5c3f97',
                                        padding: '5px 0'
                                    }}>
                                        <a href="#" onClick={() => navigate(`/${post.id}`)} style={{
                                            color: '#5c3f97',
                                            textDecoration: 'none',
                                            transition: 'color 0.3s'
                                        }} onMouseEnter={(e) => e.target.style.color = '#3c2d7a'}
                                            onMouseLeave={(e) => e.target.style.color = '#5c3f97'}>
                                            {post.Title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </aside>
                    </div>

                </div>
                <div className="col-md-9 d-flex justify-content-between my-4">
                    {prevPost && (
                        <button className="btn btn-custom" onClick={() => navigate(`/${prevPost.id}`)}>
                            Önceki: {prevPost.Title.slice(0, 10) + '..'}
                        </button>
                    )}
                    {nextPost && (
                        <button className="btn btn-custom" onClick={() => navigate(`/${nextPost.id}`)}>
                            Sonraki: {nextPost.Title.slice(0, 10) + '..'}
                        </button>
                    )}
                </div>
            </div>
            <style>{`
                .container img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin-left: 0;
                    margin-right: auto; 
                }

                .btn-custom {
        background-color: #5c3f97;
        color: white;
        border: none;
        padding: 10px 20px;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 25px;
        transition: background-color 0.3s, transform 0.3s;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .btn-custom:hover {
     color: white;
        background-color: #3c2d7a;
        transform: translateY(-2px);
    }

    .btn-custom:active {
        background-color: #2c1f5b;
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.container pre {
  background-color: #e0e0e0; /* Daha koyu gri arka plan */
  color: #2c3e50; /* Daha koyu bir metin rengi */
  padding: 15px; /* İç boşluk */
  border-radius: 8px; /* Kenarları daha belirgin yuvarlama */
  font-family: 'Courier New', Courier, monospace; /* Monospace yazı tipi */
  overflow: auto; /* Taşan metin için kaydırma */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge ekleme */
  border-left: 4px solid #5c3f97; /* Sol tarafa renkli bir çizgi ekleme */
}



            `}</style>
        </div>
    );
};

export default PostDetail;
