import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import About from './pages/About';
import Customers from './pages/Customers';
import Contact from './pages/Contact';
import Profiles from './pages/Profiles';
import AdminLogin from './pages/AdminLogin';
import AdminPanel from './pages/AdminPanel';
import PostDetail from './pages/PostDetail';
import BlogList from './pages/BlogList';
import NotFound from './pages/NotFound';
import './App.css'
const App = () => {
  return (
    <div className='App'>
      <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkımızda" element={<About />} />
          <Route path="/musterilerimiz" element={<Customers />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/ekip" element={<Profiles />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/:postId" element={<PostDetail />} />
          <Route path="*" element={<NotFound />} /> 
          
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
