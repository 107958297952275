import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <div className="text-white py-3 px-5" style={{ backgroundColor: '#5c3f97' }}>
        <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mx-0">
          <div className="d-flex justify-content-start align-items-center">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="https://www.linkedin.com/company/forcodetr/" target="_blank" rel="noopener noreferrer" className="text-white">
                  <FaLinkedin size="2em" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/forcodetr" target="_blank" rel="noopener noreferrer" className="text-white">
                  <FaInstagram size="2em" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://github.com/ForcodeTeam" target="_blank" rel="noopener noreferrer" className="text-white">
                  <FaGithub size="2em" />
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-md-end align-items-center">
            <p className="mb-0">© 2022 Forcode</p>
          </div>
        </div>
      </div>
      <style>
        {`
          .footer-link {
            color: #5c3f97;
            text-decoration: none;
          }
          .footer-link:hover {
            color: #0056b3;
          }
          .text-success { 
            color: #5c3f97 !important;
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;
