import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const config = {
  apiKey: "AIzaSyDSaKVurdFuZ5OWnLC7VNdTCCVycJGpxBI",
  authDomain: "forcode-blog.firebaseapp.com",
  projectId: "forcode-blog",
  storageBucket: "forcode-blog.appspot.com",
  messagingSenderId: "988514787152",
  appId: "1:988514787152:web:d1b880c0baedd785475655"
};
const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default config