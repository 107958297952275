import React from 'react';
import bg from '../images/istock/i3.jpg';
import img1 from '../images/reference/A101.png';
import img2 from '../images/reference/ADEL.png';
import img3 from '../images/reference/AKSA.png';
import img4 from '../images/reference/AKTUL_KAGIT.png';
import img5 from '../images/reference/AYGAZ.png';
import img6 from '../images/reference/BASKENT_GAZ.png';
import img7 from '../images/reference/BIZIM_TOPTAN.png';
import img8 from '../images/reference/BONY_TEKSTIL.png';
import img9 from '../images/reference/CELEBI_HOLDING.png';
import img10 from '../images/reference/EAE_ELEKTIRIK.png';
import img11 from '../images/reference/ERCIYES_HOLDING.png';
import img12 from '../images/reference/EVE.png';
import img13 from '../images/reference/FINPRO.png';
import img14 from '../images/reference/GRATIS.png';
import img15 from '../images/reference/HAKMAR.png';
import img16 from '../images/reference/HAYAT_HOLDING.png';
import img17 from '../images/reference/HEPSIBURADA.png';
import img18 from '../images/reference/KAZANCI_HOLDING.png';
import img19 from '../images/reference/KONYA_SEKER.png';
import img20 from '../images/reference/KULTUR_UNIVERSITESI.png';
import img21 from '../images/reference/LIMAK.png';
import img22 from '../images/reference/NTT_DATA.png';
import img23 from '../images/reference/PPG.png';
import img24 from '../images/reference/RONESANS_HOLDING.png';
import img25 from '../images/reference/SAMPA.png';
import img26 from '../images/reference/SANKO.png';
import img27 from '../images/reference/SMART_SOLAR.png';
import img28 from '../images/reference/STILL_ARSER.png';
import img29 from '../images/reference/SUTAS.png';
import img30 from '../images/reference/TOSYALI_HOLDING.png';
import img31 from '../images/reference/TVF.png';
import img32 from '../images/reference/YILDIRIM_HOLDING.png';
import img33 from '../images/reference/YILDIZ_HOLDING.png';
import img34 from '../images/reference/YUCEL_BORU.png';
import img35 from '../images/reference/ATU.png';
import img36 from '../images/reference/BMC.png';
import ChangeTitle from '../ChangeTitle';

const Customers = () => {
    const logos = [
        img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11,
        img12, img13, img14, img15, img16, img17, img18, img19, img20, img21,
        img22, img23, img24, img25, img26, img27, img28, img29, img30, img31,
        img32, img33, img34, img35, img36
    ];

    const itemsPerSlide = 9; 
    const slides = Math.ceil(logos.length / itemsPerSlide); 
    ChangeTitle("Müşterilerimiz | Forcode");
    return (
        <div className='bg-light' style={{ marginTop: '80px'}}>
        <style>
            {`
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                filter: invert(100%);
            }
    
            .logo-container {
                display: inline-block;
                transition: transform 0.3s, box-shadow 0.3s, border 0.3s, background-color 0.3s;
                cursor: pointer;
                padding: 10px;
                width: 120px; /* Bu değerleri ihtiyacınıza göre ayarlayabilirsiniz */
                height: 120px; /* Bu değerleri ihtiyacınıza göre ayarlayabilirsiniz */
            }
    
            .logo-container:hover {
                background-color: #fff;
                border: 2px solid #e0e0e0;
                border-radius: 10px;
                transform: translateY(-5px);
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            }
    
            .logo-img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                transition: transform 0.3s;
            }
    
            .logo-container:hover .logo-img {
                transform: scale(1.05);
            }
            `}
        </style>
        <div
            className='w-100 position-relative'
            style={{
                maxHeight: '400px',
                overflow: 'hidden',
            }}
        >
            <img
                className="d-block w-100"
                src={bg}
                alt="Background"
                style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
            />
    
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
                    zIndex: 1,
                }}
            >
                <div className='d-flex flex-column justify-content-center align-items-center h-100 p-3'>
                    <div className='col-12 col-md-8'>
                        <p className='text-white text-center' style={{ fontSize: 'calc(0.8rem + 0.5vw)', padding: '0 1rem' }}>
                            Güçlü referanslarımız ve başarılı projelerimizle, SAP ABAP danışmanlık hizmetlerinde güvenilir bir iş ortağı olmanın gururunu yaşıyoruz.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
        <div className='container  py-5'>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div className="carousel-inner">
                    {Array.from({ length: slides }).map((_, slideIndex) => (
                        <div className={`carousel-item ${slideIndex === 0 ? 'active' : ''}`} key={slideIndex}>
                            <div className="row">
                                {logos.slice(slideIndex * itemsPerSlide, (slideIndex + 1) * itemsPerSlide).map((logo, index) => (
                                    <div key={index} className="col-md-4 col-sm-6 d-flex justify-content-center align-items-center p-2">
                                        <div className="logo-container">
                                            <img
                                                src={logo}
                                                alt="Firma Logosu"
                                                className="logo-img"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{ zIndex: 2 }}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style={{ zIndex: 2 }}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
    
    );
};

export default Customers;
