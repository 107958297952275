import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import saplogo from '../images/saplogo.png';
import sap1 from '../images/istock/i12.jpg';
import sap2 from '../images/istock/i13.jpg';
import sap3 from '../images/istock/i14.jpg';
import bg from '../images/istock/i8.jpg';
import Carousel from '../components/Carousel';
import { useNavigate } from "react-router-dom";
import img1 from '../images/reference/A101.png';
import img2 from '../images/reference/HEPSIBURADA.png';
import img5 from '../images/reference/PPG.png';
import img3 from '../images/reference/TVF.png';
import img4 from '../images/reference/SANKO.png';





const Home = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/iletisim");
    };

    return (
        <div className='' style={{ backgroundColor: '#F5F5F5', marginTop: '80px' }}>

            <Carousel />
            <div className="container mt-5 p-5 " >
                <div className='row  my-5'>
                    <div className='col-lg-7 col-md-12 col-sm-12'  >

                        <div className="section-title  mx-md-5 ">
                            <h1 className="title" style={{ color: '#5c3f97 ', }}>SAP ABAP Çözümleri ile Geleceğinizi Şekillendirin!</h1>
                            <p className="text mt-4" style={{ color: 'GrayText' }} >
                                Dijital dönüşüm ve etkin veri yönetimi için SAP ABAP çözümleriyle tanışın.
                                Forcode Bilişim Hizmetleri, işletmenizin özel ihtiyaçlarına yönelik SAP ABAP
                                geliştirmeleriyle süreçlerinizi optimize etmenize, yenilikçi yazılım çözümleri sunarak
                                iş verimliliğinizi artırmanıza ve kapsamlı destek hizmetleri ile operasyonlarınızı sorunsuz
                                yürütmenize yardımcı olur.
                            </p>
                            <p style={{ color: 'GrayText' }}>
                                Forcode Bilişim Hizmetleri ile dinamik ve esnek bir yapıya kavuşun; ABAP ile işletmenizin
                                yeni vizyonunu gerçeğe dönüştürün.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-12 col-sm-12 ' style={{ borderRadius: '10px', overflow: 'hidden' }}>
                        <img
                            className="d-block w-100"
                            src={sap1}
                            alt="Sap Logo"
                            style={{ borderRadius: '30px' }}
                        />
                    </div>


                </div>
            </div>

            <div className='row justify-content-center my-5 mx-0 col-md-12' style={{ backgroundColor: 'white' }}>
                <div className='col-md-2 text-center m-3'>
                    <img
                        src={img1}
                        alt="Sap Logo"
                        style={{ maxWidth: '100%', maxHeight: '80px', objectFit: 'contain' }}
                    />
                </div>
                <div className='col-md-2 text-center m-3'>
                    <img
                        src={img2}
                        alt="Sap Logo"
                        style={{ maxWidth: '100%', maxHeight: '80px', objectFit: 'contain' }}
                    />
                </div>
                <div className='col-md-2 text-center m-3'>
                    <img
                        src={img3}
                        alt="Sap Logo"
                        style={{ maxWidth: '100%', maxHeight: '80px', objectFit: 'contain' }}
                    />
                </div>
                <div className='col-md-2 text-center m-3'>
                    <img
                        src={img4}
                        alt="Sap Logo"
                        style={{ maxWidth: '100%', maxHeight: '80px', objectFit: 'contain' }}
                    />
                </div>
                <div className='col-md-2 text-center m-3'>
                    <img
                        src={img5}
                        alt="Sap Logo"
                        style={{ maxWidth: '100%', maxHeight: '80px', objectFit: 'contain' }}
                    />
                </div>

            </div>

            <div className="container  mt-5 p-5">
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12 my-5' style={{ borderRadius: '10px', overflow: 'hidden' }}>
                        <img
                            className="d-block w-100"
                            src={sap2}
                            alt="Sap Logo"
                            style={{ borderRadius: '30px' }}
                        />
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12  my-5' style={{ backgroundColor: '#5c3f97 ', borderRadius: '10px', padding: '20px' }}>
                        <div className="section-title p-3">
                            <h3 className="title" style={{ color: 'white' }}>Yazılımda Yenilikçi Çözümler</h3>
                            <p className="text " style={{ color: 'white' }}>
                                Günümüzün hızla değişen iş dünyasında, yenilikçi yazılım çözümleri ile öne çıkmak hiç bu kadar
                                önemli olmamıştı. Forcode Bilişim Hizmetleri olarak, işletmelerin verimliliklerini artırmalarına ve
                                rekabet avantajı elde etmelerine yardımcı olacak en son teknolojileri sunuyoruz. Müşterilerimizin
                                ihtiyaçlarına özel olarak geliştirdiğimiz yazılım çözümleri, iş süreçlerini optimize ederken,
                                maliyetleri düşürmeyi ve operasyonel verimliliği artırmayı hedefler. Yenilikçi yaklaşımımız sayesinde,
                                her sektördeki işletmelerin dijital dönüşüm süreçlerine katkıda bulunuyoruz.
                            </p>
                            <h3 className="title" style={{ color: 'white' }}>Geleceği Şekillendiren Yazılım Çözümleri</h3>
                            <p className="text " style={{ color: 'white' }}>
                                Forcode Bilişim Hizmetleri, yazılım geliştirme alanında en son trendleri takip ederek, müşterilerimize
                                esnek, ölçeklenebilir ve güvenilir çözümler sunar. Uzman ekibimiz, her projeyi titizlikle ele alır ve
                                işletmenizin ihtiyaçlarına en uygun yazılım çözümlerini geliştirir. Geleceğe hazırlıklı olmak ve işinizi
                                bir üst seviyeye taşımak için yenilikçi yazılım çözümlerimize güvenin. Forcode Bilişim Hizmetleri ile
                                dijital dönüşüm yolculuğunuzda size rehberlik etmekten gurur duyuyoruz.

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="  " style={{ backgroundColor: 'white' }} >
                <div className='container mt-5 p-5'>
                    <div className='row my-5'>

                        <div className='col-lg-7 col-md-12 col-sm-12'  >

                            <div className="section-title  mx-md-5 my-5">
                                <h1 className="title" style={{ color: '#5c3f97' }}>SAP ABAP'da Sizin İçin En İyi Odaklı Çözümler</h1>
                                <p className="text mt- " style={{ fontWeight: 'bold', color: 'GrayText' }} >
                                    Forcode Bilişim Hizmetleri olarak, SAP ABAP çözümlerinde uzmanız ve işletmenizin dijital dönüşümünü hızlandırmak için buradayız. Odaklandığımız alanlar:
                                </p>
                                <p className="text  " style={{ color: 'GrayText' }} >
                                    Özelleştirilmiş ABAP Geliştirmeleri: İşletmenize özel ABAP çözümleri ile süreçlerinizi optimize ediyor, iş verimliliğinizi artırıyoruz.
                                </p>
                                <p className="text  " style={{ color: 'GrayText' }} >
                                    SAP Modülleri Entegrasyonu: Finans, lojistik, insan kaynakları ve daha birçok alanda SAP modülleri entegrasyonu ile tüm süreçlerinizi tek platformda yönetmenize yardımcı oluyoruz.
                                </p>
                                <p className="text  " style={{ color: 'GrayText' }} >
                                    Raporlama ve Analiz: Gelişmiş raporlama çözümleri ile veriye dayalı kararlar almanızı sağlıyoruz.
                                </p>
                                <p className="text  " style={{ color: 'GrayText' }} >
                                    Bakım ve Destek: Kurulum, bakım ve destek hizmetleri ile SAP ABAP çözümlerinizin her aşamasında yanınızdayız.
                                </p>
                                <p className="text  " style={{ color: 'GrayText' }} >
                                    Performans Optimizasyonu: ABAP kod ve database optimizasyonları ile SAP sistemlerinizin performansını artırıyoruz.
                                </p>
                                <p className="text " style={{ fontWeight: 'bold', color: 'GrayText' }} >
                                    Forcode Bilişim Hizmetleri olarak, SAP ABAP çözümlerimizle işletmenizin dijital dönüşümüne öncülük ediyoruz. İşinizi geleceğe taşımak için bize güvenin.
                                </p>

                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12 col-sm-12 my-5' style={{ borderRadius: '10px', overflow: 'hidden' }}>
                            <img
                                className="d-block w-100"
                                src={sap3}
                                alt="Sap Logo"
                                style={{ borderRadius: '30px', }}
                            />
                        </div>

                    </div>


                </div>
            </div>

            <div
                className='w-100 position-relative'
                style={{
                    maxHeight: '300px',
                    overflow: 'hidden',
                    height:'auto'
                }}
            >
                <img
                    className="d-block w-100"
                    src={bg}
                    alt="Sap Logo"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
                />


                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
                    zIndex: 1,
                }}>
                    <div className='container-fluid h-100' >
                        <div className='row justify-content-center align-items-center h-100'>
                            <div className='col-md-6 text-center text-white'>
                                <h1 className='text-center px-5 mx-1' style={{ fontSize: 'calc(0.8rem + 0.5vw)', padding: '0 1rem' }}>
                                    Gelin, Birlikte Çalışarak Güçlü Adımlarla Geleceği Şekillendirelim.
                                </h1>
                            </div>
                            <div className='col-md-6 d-flex justify-content-center'>
                                <button
                                    className='btn px-4 py-2 btn-lg'
                                    style={{ fontSize: 'calc(0.8rem + 0.5vw)', padding: '0 1rem',  borderRadius: '20px', backgroundColor: '#5c3f97', color: 'white' }}
                                    onClick={handleClick}
                                >
                                    İletişime Geç
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Home;
