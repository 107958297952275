import { useState } from 'react';
import bg from '../images/istock/i10.jpg';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../config';
import { collection, addDoc } from 'firebase/firestore';
import ChangeTitle from '../ChangeTitle';
const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();

        const newMessage = {
            Name: name,
            Email: email,
            Message: message,
            Read: 'false',
            CreatedAt: new Date(),
        };

        try {
            await addDoc(collection(db, 'Messages'), newMessage);
            toast.success('Mesaj başarıyla gönderildi!');
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            toast.error('Mesaj gönderilirken hata oluştu!');
        }
    };
    ChangeTitle("İletişim | Forcode");
    return (
        <div style={{ backgroundColor: '#F5F5F5', marginTop: '80px' }}>

            <div
                className='w-100 position-relative'
                style={{
                    maxHeight: '300px',
                    overflow: 'hidden',
                }}
            >
                <img
                    className="d-block w-100"
                    src={bg}
                    alt="Sap Logo"
                    style={{ objectFit: 'cover', objectPosition: 'center', height: '100%' }}
                />

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'linear-gradient(180deg, rgba(181, 126, 220, 0.65) 100%, #b57edc 100%)',
                        zIndex: 1,
                    }}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center h-100 p-3'>
                        <div className='col-12 col-md-8'>
                            <h1 className='text-white text-center' style={{ fontSize: 'calc(1.2rem + 1vw)' }}>
                                İLETİŞİM
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container  px-md-5">
                <div className='row mx-md-4 p-5 '>
                   



                    <div className='col-lg-6 col-md-12 col-sm-12  py-md-5 mb-2' >
                        <div className='p-md-5' style={{ backgroundColor: '#5c3f97', borderRadius: '10px', padding: '20px', minHeight:'100%' }}>
                            <div className="section-title">
                                <h3 className="title mb-4" style={{ color: 'white' }}>İSTANBUL</h3>
                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <FaLocationDot color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>Adres</p>
                                            <p className="text" style={{ color: 'white' }}>
                                            Cevizli Mah. Ritim İstanbul B Blok No:44 Ofis:140 Maltepe/İSTANBUL
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <FaPhoneAlt color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>Telefon</p>
                                            <p className="text" style={{ color: 'white' }}>
                                                +90 532 638 9058
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <IoMail color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>E-mail</p>
                                            <a href="mailto:info@forcode.com.tr" className="text" style={{ color: 'white' }}>
                                                info@forcode.com.tr
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='col-lg-6 col-md-12 col-sm-12  py-md-5 mb-2 '>
                        <div className='p-md-5' style={{ backgroundColor: '#5c3f97', borderRadius: '10px', padding: '20px', minHeight:'100%' }}>
                            <div className="section-title">
                                <h3 className="title mb-4" style={{ color: 'white' }}>KOCAELİ</h3>
                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <FaLocationDot color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>Adres</p>
                                            <p className="text" style={{ color: 'white' }}>
                                            Bilişim Vadisi Muallimköy Mah. Deniz Cad. No: 143/8 1.1.C1 Blok Zemin Kat Kapı No: Z01 Gebze/KOCAELİ
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <FaPhoneAlt color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>Telefon</p>
                                            <p className="text" style={{ color: 'white' }}>
                                            +90 532 638 9058
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-start'>
                                    <div className='col-1'>
                                        <IoMail color='white' size='18px' />
                                    </div>
                                    <div className='col-11'>
                                        <div>
                                            <p className="title mb-0" style={{ color: 'white', fontWeight: 'bold' }}>E-mail</p>
                                            <a href="mailto:info@forcode.com.tr" className="text" style={{ color: 'white' }}>
                                                info@forcode.com.tr
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <div className="container px-md-5 pb-5">
                    <ToastContainer />

                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow">
                                <h2 className="title text-center mb-4" style={{color:'GrayText'}}>İletişime Geçin</h2>
                                <div className="form-group mb-3">
                                    <label htmlFor="name" className="form-label">İsim</label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Adınızı girin"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="form-label">E-posta</label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="E-posta adresinizi girin"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="message" className="form-label">Mesaj</label>
                                    <textarea
                                        id="message"
                                        className="form-control"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Mesajınızı yazın"
                                        rows="4"
                                        required
                                    />
                                </div>
                                <button type="submit" style={{backgroundColor:'#5c3f97', color:'white'}} className="btn w-100">Gönder</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );


};

export default Contact;
